import {
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  ElementRef,
  Input,
  OnDestroy,
  Renderer2,
  ViewContainerRef,
} from '@angular/core';
import { MatButton } from '@angular/material/button';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'button[isBusy]',
})
export class BusyButtonDirective implements OnDestroy {
  private icon: ComponentRef<LoadingIconComponent>;
  @Input() disableAfterBusy = false;
  @Input()
  public set isBusy(value: boolean | null) {
    if (value === true) {
      this.setBusy();
    }
    if (value === false) {
      this.setIdle();
    }
  }

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private viewContainerRef: ViewContainerRef,
    private matButton: MatButton,
    private renderer: Renderer2,
    private elementRef: ElementRef
  ) {
    this.loadIcon();
  }

  ngOnDestroy(): void {
    this.icon?.destroy();
  }

  private loadIcon(): void {
    const iconFactory = this.componentFactoryResolver.resolveComponentFactory(LoadingIconComponent);
    this.viewContainerRef.clear();
    this.icon = this.viewContainerRef.createComponent(iconFactory);
    const iconElement = this.icon.instance.elementRef.nativeElement;
    this.renderer.addClass(iconElement, 'mat-button-wrapper');
    this.renderer.appendChild(this.elementRef.nativeElement, iconElement);
  }

  private setBusy(): void {
    this.matButton.disabled = true;
    this.icon.instance.show = true;
  }

  private setIdle(): void {
    this.icon.instance.show = false;
    if (!this.disableAfterBusy) {
      this.matButton.disabled = false;
    }
  }
}

@Component({
  template: '<mat-icon *ngIf="show"><mat-spinner diameter="20"></mat-spinner></mat-icon>',
})
export class LoadingIconComponent {
  @Input() public show = false;

  constructor(public elementRef: ElementRef) {}
}
