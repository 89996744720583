import { Component, NgZone, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { debounceTime } from 'rxjs/operators';
import { AuthService } from '../../../../auth/auth.service';
import { KeyValuePair } from '../../../../shared/models/key-value-pair';
import { DistrictService } from '../../../../shared/services/district/district.service';
import { DhhPendingAppointmentDto } from '../../../models/DhhDtos';
import { DhhLookupsService } from '../../../services/dhh-lookups.service';
import { DhhRoutingService } from '../../../services/dhh-routing.service';
import { DhhService } from '../../../services/dhh.service';
import { DhhCancelLearnerAppointmentComponent } from '../../shared/modals/dhh-cancel-learner-appointment/dhh-cancel-learner-appointment.component';
import { DhhPrintAppointmentConfirmationLetterComponent } from '../../shared/modals/dhh-print-appointment-confirmation-letter/dhh-print-appointment-confirmation-letter.component';

@Component({
  selector: 'app-dhh-pending-appointments',
  templateUrl: './dhh-pending-appointments.component.html',
  styleUrls: ['./dhh-pending-appointments.component.scss'],
})
export class DhhPendingAppointmentsComponent implements OnInit {
  dhhAudiologistOptions: KeyValuePair[] = [];
  dhhSoundBoothOptions: KeyValuePair[] = [];
  dhhDistrictOptions: KeyValuePair[] = [];
  appointmentTypeOptions: KeyValuePair[] = [];
  dateSearchEnabled = false;
  printEnabled = false;

  filterFormGroup = this.fb.group({
    searchCriteriaName: [null],
    searchCriteriaAudiologist: [null],
    searchCriteriaSoundBooth: [null],
    searchCriteriaDistrict: [null],
    searchCriteriaAppointmentType: [null],
  });

  rangeFilterFormGroup = this.fb.group({
    searchCriteriaFromDate: [null, [Validators.required]],
    searchCriteriaToDate: [null, [Validators.required]],
  });

  pendingAppointments: DhhPendingAppointmentDto[] = [];
  dataSource = new MatTableDataSource<DhhPendingAppointmentDto>([]);
  displayedColumns = [
    'action',
    'appointmentDate',
    'startTime',
    'endTime',
    'learnerName',
    'dateOfBirth',
    'appointmentType',
    'audiologists',
    'birthHospital',
  ];

  get canPrintAppointment() {
    return (
      (this.authService.isDhhAudiologist || this.authService.isDhhScheduler || this.authService.isSuperAdmin) &&
      this.rangeFilterFormGroup.valid &&
      this.printEnabled &&
      this.pendingAppointments?.length > 0
    );
  }

  constructor(
    private ngZone: NgZone,
    private fb: FormBuilder,
    private readonly dhhLookupService: DhhLookupsService,
    private readonly dhhService: DhhService,
    private readonly districtService: DistrictService,
    public readonly authService: AuthService,
    private readonly dhhRoutingService: DhhRoutingService,
    private dialog: MatDialog
  ) {}

  async ngOnInit(): Promise<void> {
    await this.getLookups();

    this.getPendingAppointments(true);

    this.setFormSubscription();
  }

  setFormSubscription() {
    this.filterFormGroup.valueChanges.pipe(debounceTime(1000)).subscribe((value) => {
      if (
        (value.audiologistFilter && value.audiologistFilter.length !== 36) ||
        (value.boothFilter && value.boothFilter.length !== 36) ||
        (value.districtFilter && value.districtFilter.length !== 36)
      ) {
        return false;
      } else {
        this.getPendingAppointments();
      }
    });

    this.rangeFilterFormGroup.valueChanges.pipe(debounceTime(100)).subscribe(() => {
      this.printEnabled = false;
    });
  }

  async getLookups() {
    const soundBoothLocations = await this.dhhLookupService.getSoundBoothLocations().toPromise();
    this.dhhSoundBoothOptions = soundBoothLocations.value?.map((a) => new KeyValuePair(a.id, a.label));

    const removeChildren = (lookup) => {
      return {
        childRequired: lookup.childRequired,
        id: lookup.id,
        isDeleted: lookup.isDeleted,
        label: lookup.label,
        lookupName: lookup.lookupName,
        parentId: lookup.parentId,
      };
    };

    const locations = await this.dhhLookupService.getLocations().toPromise();
    if (locations) {
      const aeas = locations ?? [];
      const districts = aeas.flatMap((x) => x.children);
      const districtLookup = districts.map((x) => removeChildren(x)).sort((a, b) => a.label.localeCompare(b.label)) || [];
      this.dhhDistrictOptions = this.getHierarchicalLookup(districtLookup);
    }

    const audiologists = await this.dhhLookupService.getAudiologists().toPromise();
    this.dhhAudiologistOptions = audiologists.value?.map((a) => new KeyValuePair(a.id, a.label));

    const appointmentTypes = await this.dhhLookupService.getLearnerAppointmentTypes().toPromise();
    this.appointmentTypeOptions = appointmentTypes.value.map((a) => new KeyValuePair(a.id, a.label));
  }

  private getHierarchicalLookup(lookup, parentId?) {
    return lookup.filter((x) => (parentId && x.parentId === parentId) || !parentId).map((x) => new KeyValuePair(x.id, x.label));
  }

  enableDateSearch() {
    this.dateSearchEnabled = true;
  }

  disableDateSearch() {
    this.rangeFilterFormGroup.reset();
    this.dateSearchEnabled = false;
  }

  onRangeSearch() {
    this.printEnabled = true;
    this.getPendingAppointments();
  }

  onCancelRangeSearch() {
    this.disableDateSearch();
    this.getPendingAppointments();
  }

  getPendingAppointments(defaultLoad = false) {
    const searchControls = this.filterFormGroup.controls;
    const rangeFilterControls = this.rangeFilterFormGroup.controls;

    // Set the date filter to current date if this is the first load
    const currentDate = new Date();
    const fromDate = defaultLoad ? currentDate : rangeFilterControls.searchCriteriaFromDate.value;
    const toDate = defaultLoad ? currentDate : rangeFilterControls.searchCriteriaToDate.value;

    this.dhhService
      .getPendingAppointments(
        searchControls.searchCriteriaName.value,
        searchControls.searchCriteriaAudiologist.value,
        searchControls.searchCriteriaSoundBooth.value,
        searchControls.searchCriteriaDistrict.value,
        null,
        fromDate,
        toDate,
        searchControls.searchCriteriaAppointmentType.value
      )
      .subscribe(
        (result) => {
          if (result.succeeded) {
            this.pendingAppointments = result.value;
            this.dataSource.data = this.pendingAppointments;
          } else {
            this.dhhService.handleError('Failed to retrieving pending appointments.', result);
          }
        },
        (error) => {
          this.dhhService.handleError('There was an error while retrieving pending appointments.', error);
        }
      );
  }

  onCancelAppointment(learnerAppointmentId: any) {
    const dialogRef = this.dialog.open(DhhCancelLearnerAppointmentComponent, {
      width: '650px',
      data: {
        learnerAppointmentId: learnerAppointmentId,
      },
    });
    dialogRef.afterClosed().subscribe((confirmed) => {
      if (confirmed) {
        this.getPendingAppointments();
      }
    });
  }

  onPrintConfirmationLetter(learnerAppointmentId = null) {
    this.dialog.open(DhhPrintAppointmentConfirmationLetterComponent, {
      data: {
        pendingAppointments: this.pendingAppointments,
        selectedLearnerAppointmentId: learnerAppointmentId,
      },
    });
  }

  onRescheduleAppointment(appointment: any) {
    const callBackFunc = () => {
      this.ngZone.run(() => {
        this.getPendingAppointments();
      });
    };

    this.dhhRoutingService.openLearnerAppointmentScheduler(appointment.learnerId, appointment.learnerAppointmentId, callBackFunc);
  }

  onAppointmentKept(appointment: any) {
    this.dhhService.keptLearnerAppointment(appointment.learnerAppointmentId).subscribe(
      (result) => {
        if (result.succeeded) {
          this.getPendingAppointments();
        } else {
          this.dhhService.handleError('Failed to update appointment status to kept.', result);
        }
      },
      (error) => {
        this.dhhService.handleError('There was an error while updating appointment status to kept.', error);
      }
    );
  }

  onAddNewTest(learnerId: string) {
    this.dhhRoutingService.goToLhdTest(learnerId);
  }

  onAddNewConsent(learnerId: string) {
    this.dhhRoutingService.openLhdConsents(learnerId, { shouldOpenModal: true });
  }
}
