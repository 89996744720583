<mat-accordion class="accordion accordion--close accordion--short" multi>
  <mat-expansion-panel class="mat-elevation-z0">
    <mat-expansion-panel-header>
      <mat-panel-title> <h3 class="my-0 text-normal">Attendance</h3> </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="table-overflow app-table">
      <table mat-table [dataSource]="attendanceDataSource" class="w-100" matSort>
        <ng-container matColumnDef="schoolName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>School</th>
          <td mat-cell *matCellDef="let element">
            {{ element.schoolName }}
          </td>
        </ng-container>

        <ng-container matColumnDef="schoolYear">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>School Year</th>
          <td mat-cell *matCellDef="let element">
            {{ element['-SchoolYear'] }}
          </td>
        </ng-container>
        <ng-container matColumnDef="daysAttended">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Days Present</th>
          <td mat-cell *matCellDef="let element">
            {{ element.daysAttended ?? 0 | number : '1.0-0' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="daysInMembership">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Days Enrolled</th>
          <td mat-cell *matCellDef="let element">
            {{ element.daysInMembership ?? 0 | number : '1.0-0' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="noResults">
          <td mat-footer-cell *matFooterCellDef colspan="7">
            <i>No results...</i>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="attendanceDisplayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: attendanceDisplayedColumns"></tr>
        <tr
          mat-footer-row
          [hidden]="attendanceDataSource?.data?.length > 0"
          *matFooterRowDef="attendanceDataSource.data?.length === 0 ? ['noResults'] : []"
          class="example-second-footer-row"
        ></tr>
      </table>
      <mat-paginator [dataSource]="attendanceDataSource"></mat-paginator>
    </div>
  </mat-expansion-panel>

  <mat-expansion-panel class="mat-elevation-z0">
    <mat-expansion-panel-header>
      <mat-panel-title> <h3 class="my-0 text-normal">Behavior</h3> </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="table-overflow app-table">
      <table mat-table [dataSource]="behaviorDataSource" class="w-100" matSort>
        <ng-container matColumnDef="schoolName">
          <th mat-header-cell *matHeaderCellDef>School</th>
          <td mat-cell *matCellDef="let element">
            {{ element.schoolName }}
          </td>
        </ng-container>

        <ng-container matColumnDef="numInSchoolSuspensions">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>In-School Suspensions (# of incidents)</th>
          <td mat-cell *matCellDef="let element">
            {{ element.numInSchoolSuspensions }}
          </td>
        </ng-container>

        <ng-container matColumnDef="numOutSchoolSuspensions">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Out-of-School Suspensions (# of incidents)</th>
          <td mat-cell *matCellDef="let element">
            {{ element.numOutSchoolSuspensions }}
          </td>
        </ng-container>

        <ng-container matColumnDef="expulsions">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Number of Expulsions</th>
          <td mat-cell *matCellDef="let element">
            {{ element.expulsions }}
          </td>
        </ng-container>

        <ng-container matColumnDef="noResults">
          <td mat-footer-cell *matFooterCellDef colspan="7">
            <i>No results...</i>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="behaviorDisplayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: behaviorDisplayedColumns"></tr>
        <tr
          [hidden]="behaviorDataSource?.data?.length > 0"
          mat-footer-row
          *matFooterRowDef="behaviorDataSource.data?.length === 0 ? ['noResults'] : []"
          class="example-second-footer-row"
        ></tr>
      </table>
      <mat-paginator [dataSource]="behaviorDataSource"></mat-paginator>
    </div>
  </mat-expansion-panel>
</mat-accordion>
