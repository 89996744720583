<mat-card class="p-0">
  <mat-accordion class="accordion accordion--close accordion--short">
    <mat-expansion-panel class="mat-elevation-z0 no-body-padding" expanded>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="display-flex flex-1 justify-content-between align-items-center flex-wrap">
            <h3 class="my-0 text-normal">Pending Appointments</h3>
            <form [formGroup]="filterFormGroup" class="display-flex align-items-center justify-content-between flex-wrap">
              <div class="mx-2 flex-1">
                <app-text-field
                  label="Search by Name"
                  aria-label="Search by Name"
                  (click)="$event.stopPropagation()"
                  formControlName="searchCriteriaName"
                ></app-text-field>
              </div>
              <div class="mx-2 flex-1">
                <app-autocomplete
                  (click)="$event.stopPropagation()"
                  [options]="dhhAudiologistOptions"
                  formControlName="searchCriteriaAudiologist"
                  label="Filter by Audiologist"
                  aria-label="Filter by Audiologist"
                ></app-autocomplete>
              </div>
              <div class="mx-2 flex-1">
                <app-autocomplete
                  (click)="$event.stopPropagation()"
                  [options]="dhhSoundBoothOptions"
                  formControlName="searchCriteriaSoundBooth"
                  label="Filter by Booth"
                  aria-label="Filter by Booth"
                ></app-autocomplete>
              </div>
              <div class="mx-2 flex-1">
                <app-autocomplete
                  (click)="$event.stopPropagation()"
                  [options]="dhhDistrictOptions"
                  formControlName="searchCriteriaDistrict"
                  label="Filter by District"
                  aria-label="Filter by District"
                ></app-autocomplete>
              </div>
              <div class="mx-2 appointment-type">
                <app-autocomplete
                  (click)="$event.stopPropagation()"
                  [options]="appointmentTypeOptions"
                  formControlName="searchCriteriaAppointmentType"
                  label="Filter by Appointment Type"
                  aria-label="Filter by Appointment Type"
                >
                </app-autocomplete>
              </div>
              <div class="mx-2">
                <app-clear-form-button [formGroup]="filterFormGroup"></app-clear-form-button>
              </div>
            </form>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div class="row">
        <div class="col-md-12">
          <div class="display-flex align-items-center justify-content-end">
            <button
              *ngIf="!dateSearchEnabled"
              (click)="enableDateSearch()"
              color="primary"
              mat-raised-button
              aria-label="Search by Date Range"
              tabindex="0"
            >
              Search by Date Range
            </button>

            <ng-container *ngIf="dateSearchEnabled">
              <ng-container [formGroup]="rangeFilterFormGroup">
                <app-date-picker-v2
                  class="mr-2"
                  label="Choose Start Date"
                  formControlName="searchCriteriaFromDate"
                  [showAsterisk]="true"
                  [required]="true"
                  [max]="rangeFilterFormGroup.get('searchCriteriaToDate').value"
                >
                </app-date-picker-v2>

                <app-date-picker-v2
                  class="mr-2"
                  label="Choose End Date"
                  formControlName="searchCriteriaToDate"
                  [required]="true"
                  [min]="rangeFilterFormGroup.get('searchCriteriaFromDate').value"
                >
                </app-date-picker-v2>

                <button
                  (click)="onRangeSearch()"
                  class="mr-2"
                  color="primary"
                  mat-raised-button
                  aria-label="Search"
                  tabindex="0"
                  [disabled]="rangeFilterFormGroup.invalid"
                >
                  Search
                </button>
                <button (click)="onCancelRangeSearch()" class="mr-2" color="primary" mat-raised-button aria-label="Cancel" tabindex="0">
                  Cancel
                </button>
                <button
                  class="mr-2"
                  color="primary"
                  mat-raised-button
                  aria-label="Print Confirmation Letters"
                  (click)="onPrintConfirmationLetter()"
                  [disabled]="!canPrintAppointment"
                  tabindex="0"
                >
                  Print Confirmation Letters
                </button>
              </ng-container>
            </ng-container>
          </div>

          <app-dhh-pending-appointments-table
            [pendingAppointments]="pendingAppointments"
            [displayedColumns]="displayedColumns"
            (cancelEvent)="onCancelAppointment($event)"
            (printConfirmationEvent)="onPrintConfirmationLetter($event)"
            (rescheduleEvent)="onRescheduleAppointment($event)"
            (keptEvent)="onAppointmentKept($event)"
            (addNewTestEvent)="onAddNewTest($event)"
            (addNewConsentEvent)="onAddNewConsent($event)"
          ></app-dhh-pending-appointments-table>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</mat-card>
