import { AfterViewChecked, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import dayjs from 'dayjs';
import { Subject, timer } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { ReferralService } from 'src/app/child-find/early-access-referral/early-access-referral.service';
import { Referral } from 'src/app/child-find/early-access-referral/referral';
import { EvaluationUserService } from 'src/app/evaluation/services/evaluation-user.service';
import { shortDateFormat } from 'src/app/shared/dateTimeHelpers';
import { getFirstLocationName } from 'src/app/shared/locationHelpers';
import { FamilyMemberType, LearnerSummary } from 'src/app/shared/models/learner';
import { openPdfWindow, openPrintWindow } from 'src/app/shared/windowHelpers';
import { CaseSummary, FamilyMeetingCreate, FamilyMeetingRead, IntakeType } from '../../shared/models/case';
import { KeyValuePair } from '../../shared/models/key-value-pair';
import { AchieveConfigService } from '../../shared/services/achieve-config-service/achieve-config.service';
import { CaseService } from '../../shared/services/case/case.service';
import { LearnerService } from '../../shared/services/learner/learner.service';
import { NotificationService } from '../../shared/services/notification.service';
import { PopupProxy } from '../../shared/services/popup-proxy';
import { phoneValidator } from '../../shared/validators';
import { EcProgramsService } from '../early-childhood/ec-programs/ec-programs.service';
import { FamilyMeetingService } from './family-meeting.service';
import { MeetingParticipantRead } from './participants-list/meeting-participants';
import { NoticeOfMeetingPrintComponent } from './print/notice-of-meeting-print/notice-of-meeting-print.component';
import { PurposeOfMeeting } from './purpose-of-meeting/purpose-of-meeting';
import { PurposeOfMeetingService } from './purpose-of-meeting/purpose-of-meeting.service';

const purposeRequiredValidator: ValidatorFn = (control: FormControl): ValidationErrors | null => {
  const purposes = control.value;

  return purposes.length === 0 ? { required: true } : null;
};

const participantsRequiredValidator: ValidatorFn = (control: FormControl): ValidationErrors | null => {
  const participants = control.value;

  return participants.length === 0 ? { participantsRequired: true } : null;
};

@Component({
  selector: 'app-family-meeting',
  templateUrl: './family-meeting.component.html',
  styleUrls: ['./family-meeting.component.scss'],
})
export class FamilyMeetingComponent implements OnInit, AfterViewChecked {
  learnerId: string;
  learner: LearnerSummary;
  activeCases: Array<CaseSummary>;
  allPurposes: PurposeOfMeeting[];

  contactOptions: KeyValuePair[] = [];
  familyMemberOptions: KeyValuePair[] = [];
  inviteIsReferrer: boolean;
  today = dayjs().startOf('day').toDate();
  otherSelected = false;
  dataLoaded = false;
  alternateContact = false;
  initialHomeVisitSelected = false;
  displayIsReferralSource = false;
  pageTitle: string;
  formGroup: FormGroup = new FormGroup({
    purposes: new FormControl([], [purposeRequiredValidator]),
    date: new FormControl(null, [Validators.required]),
    time: new FormControl(null, Validators.required),
    location: new FormControl(null, Validators.required),
    otherPurposeDescription: new FormControl(null),
    contactPerson: new FormControl(null, Validators.required),
    alternateContactName: new FormControl(null),
    alternateContactPosition: new FormControl(null),
    alternateContactEmail: new FormControl(null, Validators.email),
    alternateContactPhone: new FormControl(null, phoneValidator),
    participants: new FormControl([]),
    questionnaireRecipientId: new FormControl(null),
    leaRepresentative: new FormControl(null),
  });
  minScheduleMeetingDate = dayjs().subtract(6, 'day').toDate();
  shortDateFormat = shortDateFormat;
  referral: Referral;
  dateWatcher$ = new Subject();
  errorAddingFamily: string = undefined;
  isIepPurpose: boolean;
  ecpContacts: MeetingParticipantRead[] = [];
  isSaving = false;
  requestAgreementToExcuse = false;

  currentIntake: IntakeType;
  intakeType = IntakeType;

  get currentCase(): CaseSummary {
    return this.activeCases.length > 1 ? this.activeCases.find((x) => x.intakeType === this.currentIntake) : this.activeCases[0];
  }

  constructor(
    private caseService: CaseService,
    private referralService: ReferralService,
    private evaluationUserService: EvaluationUserService,
    private purposeOfMeetingService: PurposeOfMeetingService,
    private changeDetector: ChangeDetectorRef,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private ecProgramsService: EcProgramsService,
    private notificationService: NotificationService,
    private learnerService: LearnerService,
    private readonly achieveConfigService: AchieveConfigService,
    private familyMeetingService: FamilyMeetingService
  ) {}

  async ngOnInit(): Promise<void> {
    this.learnerId = this.route.snapshot.paramMap?.get('learnerId');
    const evaluationId = this.route.snapshot.paramMap?.get('evaluationId');

    this.learner = await this.learnerService.getLearnerSummary(this.learnerId).toPromise();
    if (this.learner.eligibleInLegacySystemPartC === true) {
      this.minScheduleMeetingDate = dayjs().subtract(90, 'day').toDate();
    }
    this.pageTitle = 'Scheduler - ' + this.learner.fullName;

    this.activeCases = await this.learnerService.getActiveCases(this.learnerId).toPromise();

    this.allPurposes = await this.getPurposes(this.learner);

    const activePartCCase = this.activeCases.find((x) => x.intakeType === IntakeType.PartC || x.intakeType === IntakeType.PartCLate);

    this.currentIntake = activePartCCase ? IntakeType.PartC : IntakeType.PartB;
    if (activePartCCase) {
      this.referralService.getReferral(activePartCCase.earlyAccessReferralId).subscribe((referral) => (this.referral = referral));
    }

    this.ecProgramsService.getContacts(this.learnerId).subscribe((contacts) => (this.ecpContacts = contacts));

    const activeCaseUsers = this.activeCases.flatMap((x) => x.caseUsers).filter((v, i, s) => s.map((y) => y.id).indexOf(v.id) === i);

    this.contactOptions = activeCaseUsers.map((x) => new KeyValuePair(x.id, x.fullName));

    this.familyMemberOptions = this.learner.familyMembers.map((x) => new KeyValuePair(x.id, x.fullName));

    this.setDefaultContactPerson();
    this.setContactValidators();

    const familyMembers = this.learner.familyMembers
      .filter((fm) => fm.allowPortalAccess)
      .map((fm) => {
        return {
          familyMemberId: fm.id,
          agency: 'Family',
          role: 'Family',
          roleOther: null,
          fullName: fm.fullName,
          email: fm.email,
          agencyId: null,
          medicalSpecialistId: null,
          learnerId: null,
          userId: null,
          excusalDate: null,
        };
      });

    if (this.learner.surrogates?.length > 0) {
      const surrogates = this.learner.surrogates
        .filter((x) => x.discontinuedOn === null)
        .map((x) => {
          return {
            familyMemberId: null,
            agency: 'N/A',
            role: 'Surrogate',
            roleOther: null,
            fullName: x.name,
            email: x.email,
            agencyId: null,
            medicalSpecialistId: null,
            learnerId: this.learnerId,
            userId: null,
            surrogateId: x.id,
            excusalDate: null,
          };
        });
      familyMembers.push(...surrogates);
    }

    if (this.learner.isSecondaryTransition && this.learner.activeCases.some((x) => x.intakeType === IntakeType.PartB)) {
      familyMembers.push({
        familyMemberId: null,
        agency: 'Family',
        role: 'Family',
        roleOther: null,
        fullName: this.learner.fullName,
        email: this.learner.email,
        agencyId: null,
        medicalSpecialistId: null,
        learnerId: this.learnerId,
        userId: null,
        excusalDate: null,
      });
    }

    if (evaluationId) {
      this.evaluationUserService.get(evaluationId).subscribe((evalUsers) => {
        const participants = evalUsers.map((u) => {
          return {
            familyMemberId: null,
            agency: getFirstLocationName(u.aeas),
            role: 'Evaluation Team Member',
            roleOther: null,
            fullName: u.name,
            email: u.email,
            agencyId: null,
            medicalSpecialistId: null,
            learnerId: null,
            userId: u.userId,
            excusalDate: null,
          };
        });

        this.formGroup.get('participants').setValue([...familyMembers, ...participants]);
      });

      this.dataLoaded = true;
    } else {
      const caseUsers = activeCaseUsers.map((fm) => {
        return {
          familyMemberId: null,
          agency: fm.aea,
          role: fm.profession,
          roleOther: null,
          fullName: fm.fullName,
          email: fm.email,
          agencyId: null,
          medicalSpecialistId: null,
          learnerId: null,
          userId: fm.id,
          excusalDate: null,
        };
      });
      this.formGroup.get('participants').setValue([...familyMembers, ...caseUsers]);

      this.dataLoaded = true;
    }

    // Removing for now, don't want to dig through commits to bring it back later if they want it
    // const purpose = (window as any).purpose;
    // if (purpose) {
    //   this.formGroup.controls.purposes.setValue([purpose]);
    // }

    this.formGroup.controls.purposes.valueChanges.subscribe((purposes) => {
      this.purposeChange();

      const iepPurpose = this.allPurposes.find((x) => x.isIEP || x.isIEPAmendment);
      const isIepPurpose = purposes.find((x) => x === iepPurpose?.id);
      if (isIepPurpose) {
        this.isIepPurpose = true;
        this.addEarlyChildhoodProgramParticipants();
        const stPurpose = this.allPurposes.find((x) => x.isSecondaryTransition);
        const isStPurpose = purposes.find((x) => x === stPurpose?.id);
        if (isStPurpose) {
          this.addLearnerAsParticipant();
        }
      } else {
        if (this.isIepPurpose) {
          this.removeEarlyChildhoodProgramParticipants();
        }
        this.isIepPurpose = false;
      }
    });

    // TODO: Add back in once 18790 is figured out
    // this.familyMeetingService.hasPartBPurposeSelected$.subscribe((value) => {
    //   const leaRepresentativeControl = this.formGroup.get('leaRepresentative');
    //   if (value) {
    //     leaRepresentativeControl.addValidators(Validators.required);
    //   } else {
    //     leaRepresentativeControl.removeValidators(Validators.required);
    //     leaRepresentativeControl.setValue(null);
    //   }
    //   leaRepresentativeControl.updateValueAndValidity();
    // });

    this.determineLearnerAsParticipant();
  }

  excusalCheck() {
    this.requestAgreementToExcuse = !this.requestAgreementToExcuse;
  }

  private async getPurposes(learner: LearnerSummary): Promise<Array<PurposeOfMeeting>> {
    if (!learner || learner?.activeCases?.length === 0) {
      return;
    }

    const intakeTypes = this.learner.activeCases.map((x) => x.intakeType);

    const promises = intakeTypes.map((x) =>
      this.purposeOfMeetingService
        .get(x)
        .pipe(map((purposes) => this.filterPurposes(purposes)))
        .toPromise()
    );

    promises.push(this.purposeOfMeetingService.get(IntakeType.Both).toPromise());

    const purposeCollections = await Promise.all(promises);
    return purposeCollections.flatMap((x) => x);
  }

  private filterPurposes(purposes: Array<PurposeOfMeeting>): Array<PurposeOfMeeting> {
    let filteredPurposes = [...purposes];

    if (this.learner.finalizedIfsp || this.learner.eligibleInLegacySystemPartC === true) {
      filteredPurposes = filteredPurposes.filter((x) => !x.isInitialHomeVisit);
    }

    if (!this.learner.showTransitionPlanning) {
      filteredPurposes = filteredPurposes.filter((x) => !x.isSecondaryTransition);
    }

    if (this.activeCases?.find((x) => x.intakeType === IntakeType.PartB)?.exitFinalizedOn) {
      filteredPurposes = filteredPurposes.filter((x) => x.intakeType !== IntakeType.PartB);
    }

    return filteredPurposes;
  }

  private determineLearnerAsParticipant() {
    const oldEnough = this.calculateIsOfAge(this.today);
    if (oldEnough) {
      this.addLearnerAsParticipant();
    } else {
      this.formGroup
        .get('date')
        .valueChanges.pipe(takeUntil(this.dateWatcher$))
        .subscribe((value) => {
          const isOfAge = this.calculateIsOfAge(value);
          if (isOfAge) {
            this.addLearnerAsParticipant();
          }
        });
    }
  }

  private removeEarlyChildhoodProgramParticipants() {
    const participants = this.formGroup.get('participants').value as MeetingParticipantRead[];
    this.ecpContacts.forEach((contact) => {
      const participantIndex = participants.findIndex((x) => x.fullName === contact.fullName && x.email === contact.email);
      if (participantIndex > -1) {
        participants.splice(participantIndex, 1);
      }
    });
    this.formGroup.get('participants').setValue([...participants]);
  }

  private addEarlyChildhoodProgramParticipants() {
    this.ecpContacts.forEach((contact) => {
      const participants = this.formGroup.get('participants').value as MeetingParticipantRead[];
      if (!participants.find((x) => x.fullName === contact.fullName && x.email === contact.email)) {
        const newParticipant = {
          familyMemberId: null,
          agency: contact.agency,
          role: null,
          roleOther: null,
          fullName: contact.fullName,
          email: contact.email,
          agencyId: null,
          medicalSpecialistId: null,
          learnerId: this.learnerId,
          userId: null,
          excusalDate: null,
        };
        this.formGroup.get('participants').setValue([...participants, newParticipant]);
      }
    });
  }

  private calculateIsOfAge(value) {
    const meetingAgeEndDate = dayjs(value).add(1, 'year').subtract(1, 'day').toDate();
    const fourteenthBirthday = dayjs(this.learner.dateOfBirth).add(14, 'year').toDate();
    return meetingAgeEndDate >= fourteenthBirthday;
  }

  private addLearnerAsParticipant() {
    const participants = this.formGroup.get('participants').value as MeetingParticipantRead[];
    if (!participants.find((x) => x.learnerId === this.learnerId)) {
      const newParticipant = {
        familyMemberId: null,
        agency: 'Family',
        role: 'Family',
        roleOther: null,
        fullName: this.learner.fullName,
        email: this.learner.email,
        agencyId: null,
        medicalSpecialistId: null,
        learnerId: this.learnerId,
        userId: null,
        excusalDate: null,
      };
      this.formGroup.get('participants').setValue([...participants, newParticipant]);
    }
    this.dateWatcher$.next(undefined);
  }

  addParticipant(newParticipant: MeetingParticipantRead) {
    const participants = this.formGroup.get('participants').value as MeetingParticipantRead[];
    const existsInList = !!participants.find(
      (p) =>
        p.fullName === newParticipant.fullName &&
        p.agency === newParticipant.agency &&
        p.role === newParticipant.role &&
        p.roleOther === newParticipant.roleOther
    );

    if (existsInList) {
      return;
    }

    this.formGroup.get('participants').setValue([...participants, newParticipant]);
  }

  removeParticipant(participant: MeetingParticipantRead) {
    const participants = this.formGroup.get('participants').value;
    participants.splice(participants.indexOf(participant, 0), 1);
    this.formGroup.get('participants').setValue([...participants]);
  }

  async submit() {
    if (this.formGroup.controls.questionnaireRecipientId.value && this.checkEmailValid()) {
      this.errorAddingFamily =
        'The email address of this family member is blank.  Please add an email address for the family member before continuing.';
      return;
    }
    if (this.alternateContact) {
      this.formGroup.controls.contactPerson.setValue(null);
    }

    const model = await this.toModel();

    if (!this.checkMeetingPurposes(model)) {
      this.notificationService.error('Transition Planning cannot be the only meeting purpose. At least one other purpose is required.');
      return;
    }

    this.isSaving = true;

    let caseId = this.currentCase.id;
    if (this.activeCases.length > 1) {
      const intakeType = this.allPurposes.find((x) => x.id === model.purposesOfMeeting[0])?.intakeType;
      caseId = this.activeCases.find((x) => x.intakeType === intakeType)?.id;
    }

    this.caseService.createMeeting(caseId ? caseId : this.currentCase.id, model).subscribe((meeting: FamilyMeetingRead) => {
      openPdfWindow(this.learnerId, meeting.meetingNotices[0].documentId); // new meeting has only one document
      PopupProxy.familyMeetingClose.postMessage(model);
      window.close();
    });
  }

  private checkMeetingPurposes(meeting: FamilyMeetingCreate): boolean {
    const reviewIds: string[] = this.allPurposes
      .filter((x) => x.isInitialIFSP || x.isIFSP || x.isPeriodicIfsp || x.isAnnualIfsp)
      .map((x) => x.id);
    const transitionIds: string[] = this.allPurposes.filter((x) => x.label.includes('Transition')).map((x) => x.id);
    const includesReview = meeting.purposesOfMeeting.some((x) => reviewIds.includes(x));
    const includeTransition = meeting.purposesOfMeeting.some((x) => transitionIds.includes(x));

    return !(includeTransition && meeting.purposesOfMeeting?.length < 2) || includesReview || this.currentIntake === IntakeType.PartB;
  }

  private checkEmailValid() {
    const familyMember = this.learner.familyMembers.find((i) => i.id === this.formGroup.controls.questionnaireRecipientId.value);
    return !familyMember.email;
  }

  cancel() {
    PopupProxy.familyMeetingClose.postMessage(null);
    window.close();
  }

  private async toModel(): Promise<FamilyMeetingCreate> {
    const dateAndTime = await this.achieveConfigService
      .getDateTimeFromTimeString(this.formGroup.controls.date.value, this.formGroup.controls.time.value)
      .toPromise();

    const familyMeeting: FamilyMeetingCreate = {
      purposesOfMeeting: this.formGroup.controls.purposes.value,
      otherPurposeDescription: this.formGroup.controls.otherPurposeDescription.value,
      dateAndTime: dateAndTime, //dateTimeFromTimeString(this.formGroup.controls.date.value, this.formGroup.controls.time.value),
      location: this.formGroup.controls.location.value,
      contactPersonId: this.formGroup.controls.contactPerson.value,
      alternateContactName: this.formGroup.controls.alternateContactName.value,
      alternateContactEmail: this.formGroup.controls.alternateContactEmail.value,
      alternateContactPhone: this.formGroup.controls.alternateContactPhone.value,
      alternateContactPosition: this.formGroup.controls.alternateContactPosition.value,
      participants: this.formGroup.controls.participants.value,
      questionnaireRecipientId: this.formGroup.controls.questionnaireRecipientId.value,
      inviteIsReferrer: this.inviteIsReferrer,
      leaRepresentative: this.formGroup.controls.leaRepresentative.value,
    };

    const iepPurpose = this.allPurposes.find((x) => x.isIEP);
    const isIepPurpose = this.formGroup.controls.purposes.value.find((x) => x === iepPurpose?.id);
    if (isIepPurpose && this.calculateIsOfAge(this.formGroup.controls.date.value)) {
      familyMeeting.forceSecondaryTransition = true;
    }
    return familyMeeting;
  }

  // Default ContactPerson to SC/AEA
  setDefaultContactPerson() {
    if (!this.currentCase) {
      return;
    }

    const userIds = this.currentCase.caseUsers.filter((x) => x.isOwner).map((x) => x.id);
    const ownerId = this.contactOptions.find((x) => userIds.some((y) => x.key === y)).key;
    const setOwner = timer(0).subscribe(() => {
      this.formGroup.controls.contactPerson.setValue(ownerId);
      setOwner.unsubscribe();
    });
  }

  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }

  // Switches contact validators depending on if alternate contract is checked
  setContactValidators() {
    if (this.alternateContact) {
      this.formGroup.controls.contactPerson.setValidators(null);
      this.formGroup.controls.alternateContactName.setValidators([Validators.required]);
      this.formGroup.controls.alternateContactPosition.setValidators([Validators.required]);
      this.formGroup.controls.alternateContactEmail.setValidators([Validators.required, Validators.email]);
      this.formGroup.controls.alternateContactPhone.setValidators([Validators.required]);
    } else {
      this.formGroup.controls.contactPerson.setValidators([Validators.required]);
      this.formGroup.controls.alternateContactName.setValidators(null);
      this.formGroup.controls.alternateContactPosition.setValidators(null);
      this.formGroup.controls.alternateContactEmail.setValidators(null);
      this.formGroup.controls.alternateContactPhone.setValidators(null);
      this.formGroup.patchValue({
        alternateContactName: null,
        alternateContactPosition: null,
        alternateContactEmail: null,
        alternateContactPhone: null,
      });
    }
    this.formGroup.updateValueAndValidity();
  }

  purposeChange(): void {
    const purposes = this.formGroup.get('purposes').value;
    const otherId = this.allPurposes.filter((x) => x.isOther).map((x) => x.id);

    if (purposes.some((x) => otherId.includes(x))) {
      this.otherSelected = true;
      this.formGroup.controls.otherPurposeDescription.setValidators([Validators.required]);
    } else {
      this.otherSelected = false;
      this.formGroup.controls.otherPurposeDescription.setValidators([]);
      this.formGroup.controls.otherPurposeDescription.updateValueAndValidity();
    }

    if (this.learner.activeCases.some((x) => x.intakeType === IntakeType.PartC || x.intakeType === IntakeType.PartCLate)) {
      const initialHomeVisitId = this.allPurposes.find((x) => x.label === 'Initial Home Visit')?.id;

      if (purposes.includes(initialHomeVisitId)) {
        this.formGroup.controls.questionnaireRecipientId.setValidators([Validators.required]);
        this.initialHomeVisitSelected = true;
        this.formGroup.controls.questionnaireRecipientId.valueChanges.subscribe((value) => {
          this.compareReferralSource(value);
        });
      } else {
        this.initialHomeVisitSelected = false;
        this.formGroup.controls.questionnaireRecipientId.setValidators([]);
        this.formGroup.controls.questionnaireRecipientId.updateValueAndValidity();
      }
    }

    this.setParticipantsValidator();
  }

  private compareReferralSource(id: string) {
    let referralSourceEmail: string;
    if (this.referral) {
      const referralSource = this.referral.referralSource;
      switch (referralSource) {
        case FamilyMemberType.Parent1:
          referralSourceEmail = this.referral.parentInfo.parent1Email;
          break;
        case FamilyMemberType.Parent2:
          referralSourceEmail = this.referral.parentInfo.parent2Email;
          break;
        case FamilyMemberType.LivesWith1:
          referralSourceEmail = this.referral.familyInfo.livesWith1Email;
          break;
        case FamilyMemberType.LivesWith2:
          referralSourceEmail = this.referral.familyInfo.livesWith2Email;
          break;
        default:
          break;
      }
    } else {
      referralSourceEmail = this.currentCase.newChildReferralSourceEmail;
    }

    const questionnaireRecipient = this.learner.familyMembers.find((x) => x.id === id)?.email;
    if (referralSourceEmail === questionnaireRecipient) {
      this.displayIsReferralSource = false;
      this.inviteIsReferrer = true;
    } else {
      this.displayIsReferralSource = true;
      this.inviteIsReferrer = false;
    }
  }

  setParticipantsValidator() {
    if (this.formGroup.get('purposes').value.some((x) => x.formalNoticeRequired === true)) {
      this.formGroup.controls.participants.setValidators(participantsRequiredValidator);
    } else {
      this.formGroup.controls.participants.clearValidators();
      this.formGroup.controls.participants.updateValueAndValidity();
    }
  }

  async openPdf() {
    const dateAndTime = await this.achieveConfigService
      .getDateTimeFromTimeString(this.formGroup.get('date').value, this.formGroup.get('time').value)
      .toPromise();

    const purposesOfMeeting = [];
    this.formGroup.get('purposes').value.forEach((id) => {
      const purpose = this.allPurposes.find((x) => x.id === id);
      purposesOfMeeting.push(purpose.isOther ? this.formGroup.get('otherPurposeDescription').value : purpose.label);
    });
    const user = this.currentCase.caseUsers.find((x) => x.id === this.formGroup.get('contactPerson').value);
    const contactPerson = {
      fullName: user.fullName,
      phoneNumber: user.phoneNumber,
      email: user.email,
      agency: user.aea,
    };

    const printData = {
      learnerId: this.learnerId,
      familyMeeting: {
        dateAndTime,
        location: this.formGroup.get('location').value,
        status: '',
        id: '',
        contactPerson,
        participants: this.formGroup.get('participants').value,
        participantsDataSource: this.formGroup.get('participants').value,
        purposesOfMeeting,
        inviteIsReferrer: this.formGroup.get('questionnaireRecipientId').value,
        isInitialIfsp: false,
        alternateContactName: this.formGroup.get('alternateContactName').value,
        alternateContactPhone: this.formGroup.get('alternateContactPhone').value,
        alternateContactEmail: this.formGroup.get('alternateContactEmail').value,
        alternateContactPosition: this.formGroup.get('alternateContactPosition').value,
        leaRepresentative: this.formGroup.get('leaRepresentative').value,
      },
    };
    const dialogRef = openPrintWindow(this.dialog, NoticeOfMeetingPrintComponent, printData);
  }
}
