import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-family-member-access-locked',
  templateUrl: './family-member-access-locked.component.html',
  styleUrls: ['./family-member-access-locked.component.scss'],
})
export class FamilyMemberAccessLockedComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
