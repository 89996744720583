<section class="retry-container">
  <mat-card>
    <mat-card-title><h2 class="retry-title">ACHIEVE Family Portal</h2></mat-card-title>
    <mat-card-subtitle>Max Retries exceeded</mat-card-subtitle>
    <mat-card-content>
      <div class="row">
        <div class="col">
          <p>
            We are unable to link your ACHIEVE Family Portal account to this learner with the information you provided. Visit the link below
            to request assistance.
          </p>

          <p>
            <a href="/auth/family/locked">ACHIEVE Family Portal Support Request link</a>
          </p>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</section>
