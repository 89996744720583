<section class="registration-form">
  <mat-card *ngIf="invitationVerifyDto">
    <mat-card-title><h2 class="confirm-title">ACHIEVE Family Portal</h2></mat-card-title>
    <mat-card-subtitle>Confirm Invitation</mat-card-subtitle>
    <mat-card-content>
      <div class="row">
        <div class="col">
          <ng-container class="child-verification-form" [formGroup]="formGroup">
            <div class="mb-0">
              <app-text-field label="Learner's First Name" formControlName="learnerFirstName"></app-text-field>

              <app-text-field label="Learner's Last Name" formControlName="learnerLastName"></app-text-field>

              <app-text-field label="Learner's Last 4 Digitst of State ID" formControlName="learnerStateAssignedId"></app-text-field>

              <app-date-picker
                label="Learner's Date of Birth"
                controlName="learnerDateOfBirth"
                [startView]="'multi-year'"
              ></app-date-picker>
            </div>

            <hr class="input__divider" />

            <ng-container *ngIf="!invitationVerifyDto.hasPassword">
              <div class="mb-0">
                <app-text-field label="Your First Name" formControlName="familyMemberFirstName"></app-text-field>

                <app-text-field label="Your Last Name" formControlName="familyMemberLastName"></app-text-field>

                <app-text-field label="Your Email" formControlName="familyMemberEmail"></app-text-field>
              </div>
            </ng-container>

            <app-text-field label="Your Password" formControlName="password" [inputType]="'password'"></app-text-field>

            <ng-container *ngIf="!invitationVerifyDto.hasPassword">
              <app-text-field label="Confirm Your Password" formControlName="confirmedPassword" [inputType]="'password'"></app-text-field>

              <mat-error *ngIf="formGroup.touched && formGroup.hasError('passwordMismatch')"
                >Password and Confirm Password do not match.</mat-error
              >

              <p>
                <b>Password requirements</b><br />
                <small>
                  A minimum of 8 characters <br />
                  Must have at least one uppercase letter <br />
                  Must have at least one lowercase letter <br />
                  Must have a number <br />
                  Must have any one of these special characters (#?!@$%^&*-)
                </small>
              </p>
            </ng-container>

            <div class="mt-2" *ngIf="!invitationVerifyDto.hasPortalAccount">
              <div class="consent-container">
                <h3>Read and Accept Terms and Conditions</h3>
                <div>
                  <p>
                    The ACHIEVE Family Portal is an online tool developed for parents/legal guardians ("Guardians"), Learners who have
                    reached the age of majority, and other Learners with permission from Guardians (collectively, "Learners") to access
                    timely and secure information.
                    <br /><br />
                    Access to the ACHIEVE Family Portal provides Learners and Guardians many benefits, some of which include:
                  </p>
                  <ul>
                    <li>
                      Access to information and documentation in one centralized portal, including historical data such as assessment
                      information, evaluations, and IFSP/IEP documents
                    </li>
                    <li>A calendar with information about scheduled meetings and deadlines related to the Learner</li>
                    <li>Contact information for the Learner’s IFSP Service Coordinator or IEP Facilitator</li>
                    <li>Direct access to complete family surveys</li>
                    <li>Access to a wide array of online help and resources</li>
                  </ul>
                </div>

                <div class="mt-2">
                  <p>
                    All of these things make it easier for Learners and Guardians to actively participate in the Learner’s educational
                    decision-making process and improve communication between team members. This access is a free and optional service
                    available to <a href="https://sites.ed.gov/idea/regs/b/a/300.30" target="_blank">parents established under IDEA</a> and
                    Learners once they have been invited. Once Learners exit IDEA services, access to the ACHIEVE Family Portal will be
                    deactivated after 90 days. <br /><br />
                    By agreeing to activate this user account, Learners and IDEA Parents agree to abide by and support the Terms of
                    Condition of Use and user expectations set forth here:
                  </p>

                  <ul>
                    <li>
                      All IDEA Parents/Learners who use the ACHIEVE Family Portal to access information contained therein consent to
                      electronic monitoring and understand that they are granted access to a private network used as an educational tool by
                      the Iowa Department of Education employees. Account activity is electronically recorded.
                    </li>
                    <li>
                      The Department reserves the right to discontinue the use of the ACHIEVE Family Portal at any time, with, or without,
                      notice. In addition, the Department reserves the right to modify or terminate any user’s access to the ACHIEVE Family
                      Portal temporarily or permanently at any time, at its sole discretion, with notice.
                    </li>
                    <li>
                      The Department reserves the right to add, modify or delete functions viewed via the ACHIEVE Family Portal at any time
                      without notice.
                    </li>
                    <li>
                      The Department reserves the right, at its discretion, to modify, add, or delete provisions of the Terms and Conditions
                      of Use. All Learners and Guardians are responsible for periodically reviewing the Terms and Conditions of Use for such
                      changes. Continued use of the ACHIEVE Family Portal after such changes have been made indicates user’s acceptance of
                      such changes.
                    </li>
                    <li>
                      The Department, in its sole discretion, reserves the right to request that Learners or Guardians bring photo
                      identification, or other proof of identity, to the attending Area Education Agency or district in order to verify
                      their identity should any question arise concerning the validity of the acceptance of this Agreement.
                    </li>
                    <li>
                      All Learners and Guardian users must act in a responsible, ethical, and legal manner. If a Learner or Guardian is
                      identified as a security risk, they will be denied access to the site.
                    </li>
                    <li>
                      Learners and IDEA Parents must not:
                      <ul>
                        <li>Attempt to harm or destroy Area Education Agency, District, or network data.</li>
                        <li>
                          Attempt to access information concerning Learners other than their own/ Learner’(s) or attempt to access an
                          account assigned to another person.
                        </li>
                        <li>
                          Use the ACHIEVE Family Portal or the State's networks for any illegal activity, including, but not limited to
                          violation of Federal and State Data Privacy laws. Anyone found to be in violation of these laws will be subject to
                          civil and/or criminal prosecution.
                        </li>
                        <li>
                          Share your username or password or allow anyone other than yourself to use your ACHIEVE Family Portal account.
                        </li>
                        <li>Set any computer to automatically log in to the ACHIEVE Family Portal.</li>
                        <li>Intentionally disrupt the use of the network or devices attached to the network.</li>
                        <li>
                          Use the system to develop malicious programs or computer viruses that harass other users, infiltrate a computer or
                          computer system, damage the software components of a computer or computing system.
                        </li>
                        <li>Load, transmit, or intentionally receive hate mail, harassing content, and other antisocial content.</li>
                        <li>
                          Use the system to process or upload pornographic material, inappropriate text files, illegal software, or files
                          dangerous to the integrity of the local area network or any attached device.
                        </li>
                        <li>Make unauthorized copies of software or media or other copyrighted content found on the ACHIEVE system.</li>
                      </ul>
                    </li>
                    <li>
                      Learners and Guardians must:
                      <ul>
                        <li>Log out of your ACHIEVE Family Portal user account when you are not at your computer.</li>
                        <li>Agree that hardware or software shall not be destroyed, modified, damaged or abused in any way.</li>
                        <li>
                          Notify your Area Education Agency or District immediately if you identify a security problem within the ACHIEVE
                          Family Portal without demonstrating the problem to anyone else.
                        </li>
                      </ul>
                    </li>
                    <li>
                      Security Features:
                      <ul>
                        <li>
                          Access is made available through a secure Internet site. Account holders are responsible for not sharing their
                          user names or passwords and properly protecting or destroying any printed or electronic documentation generated
                          from or about the ACHIEVE Family Portal site.
                        </li>
                        <li>
                          Users will be automatically logged out of the ACHIEVE Family Portal if they leave their web browser open and
                          inactive for a period of 30 minutes.
                        </li>
                      </ul>
                    </li>
                    <li>
                      Limitation of Iowa Department of Education Liability:
                      <ul>
                        <li>
                          ACHIEVE will use all reasonable measures to protect Learner information from unauthorized viewing. THE DEPARTMENT
                          WILL NOT BE LIABLE FOR UNAUTHORIZED USE OF THE ACHIEVE FAMILY PORTAL SYSTEM OR INTERNET.
                        </li>
                        <li>
                          The Department does not promise any particular level or method of access to the ACHIEVE Family Portal for viewing
                          Learner information.
                        </li>
                        <li>
                          The Department will not be responsible for actions taken by Learners or IDEA Parents that compromise Learner
                          information.
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="mt-3">
                <app-checkbox-single
                  formControlName="consentAccepted"
                  aria-labelledby="Accept Terms and Conditions"
                  label="Accept Terms & Conditions"
                >
                </app-checkbox-single>
              </div>

              <div class="mt-4">
                <p>Access preference</p>
                <p>
                  All ACHIEVE Family Portal account holders can complete family surveys and view, download, and print unsigned consent forms
                  and IFSP/IEP records. This includes Meeting Notices, Prior Written Notices, progress monitoring data, and more.
                  <br /><br />
                  Choose how you wish to use the ACHIEVE Family Portal by selecting your preferred access type below.
                </p>
                <ul>
                  <li>
                    The <b>Interactive</b> option allows you to view records, sign consent forms electronically, and receive emails when new
                    items are available to review and sign.
                  </li>
                  <li>
                    The <b>View-Only</b> option allows you to view records, but you will not be able to sign consent forms electronically.
                    This option also prevents you from receiving emails when new documents are available to review.
                  </li>
                </ul>
                <p>If you change your mind, you can update your access preferences in your ACHIEVE Family Portal account settings later.</p>
                <div class="mt-2">
                  <app-radio-group formControlName="accessPreference" [options]="accessPreferences"></app-radio-group>
                </div>
              </div>
            </div>

            <div class="mt-2" *ngIf="invitationVerifyDto.hasPortalAccount">
              <a class="forget-password" routerLink="/auth/forgot-password">Forgot password?</a>
            </div>
          </ng-container>
        </div>
      </div>
    </mat-card-content>
    <mat-card-actions>
      <div class="row">
        <div class="col">
          <button
            mat-flat-button
            aria-label="Complete Button"
            class="buttons mt-3"
            color="primary"
            tabindex="0"
            (click)="onRegister()"
            [isBusy]="isBusy"
            [disabled]="!formGroup.valid"
          >
            Submit
          </button>
        </div>
      </div>
    </mat-card-actions>
  </mat-card>
</section>
