import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { of, Subscription, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { EvaluationDetailService } from 'src/app/evaluation/services/evaluation-detail.service';
import { AppPermissions } from 'src/app/permissions';
import { CaseSummary, IntakeType } from 'src/app/shared/models/case';
import { RoutingService } from 'src/app/shared/services/routing.service';
import { LearnerSummary } from '../../shared/models/learner';
import { LearnerService } from '../../shared/services/learner/learner.service';
import { MoveInDetailService } from '../learner-dashboard/move-in-details/move-in-detail.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { LearnerInviteModalComponent } from '../learner-dashboard/learner-invite-modal/learner-invite-modal.component';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-learner-management-header',
  templateUrl: './learner-management-header.component.html',
  styleUrls: ['./learner-management-header.component.scss'],
})
export class LearnerManagementHeaderComponent implements OnInit, OnDestroy {
  intakeTypeEnum = IntakeType;
  @Input() learnerId: string;
  @Input() learnerSummary: LearnerSummary;
  @Input() cases: CaseSummary[];
  permissions = AppPermissions;
  isAbletoView = false;
  viewReferralAdditionalRequirements = new Map<string, string[]>([
    ['TeamMember', ['ACHIEVEDataLead', 'ACHIEVEDataTechnician', 'AeaEdit', 'LeaProviderEdit', 'ServiceCoordinator']],
  ]);

  get learnerSummary$() {
    return this.learnerService.learnerSummaryChange$;
  }

  subscription = new Subscription();

  constructor(
    private evalService: EvaluationDetailService,
    private authService: AuthService,
    private dialog: MatDialog,
    private routingService: RoutingService,
    private readonly learnerService: LearnerService,
    private moveInDetailsService: MoveInDetailService,
    private notificationService: NotificationService
  ) {}

  get pageTitle() {
    return this.learnerService.learnerSummary
      ? `Learner Management - ${this.learnerService.learnerSummary?.fullName}`
      : 'Learner Management';
  }

  get hasWorkableCase() {
    return true;
  }

  get IsAbleForScheduleMeeting() {
    return this.learnerSummary.activeCases.some((x) => this.authService.isAllowedByCaseId(x.id, undefined, AppPermissions.ScheduleMeeting));
  }

  get validIntakeType() {
    return this.learnerService.learnerSummary?.activeCases.some((item) => item.intakeType === this.intakeTypeEnum.PartB);
  }

  get hasPortalAccess() {
    return this.cases ? this.cases?.some((item) => item.learner.hasPortalAccess) : this.learnerService.learnerSummary?.hasPortalAccess;
  }

  get isPortalUser() {
    return this.authService.isPortalUser;
  }

  get canInviteLearnerToPortal() {
    return this.authService.isFamily && !this.hasPortalAccess && this.validIntakeType;
  }

  async ngOnInit(): Promise<void> {
    if (!!this.learnerId && !this.learnerService.learnerSummary) {
      this.learnerService.learnerSummary = await this.loadData(this.learnerId);
    }

    if (!!this.learnerId && !this.cases) {
      this.cases = await this.getActivePartBCases();
    }

    this.moveInDetailsService.moveInDetailsUpdated$.subscribe(async (learnerId) => {
      this.learnerService.learnerSummary = await this.loadData(learnerId);
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  helpToggled(e: any) {
    this.dialog.open(DialogTemporaryHelpComponent, { disableClose: false });
  }

  onScheduleMeeting() {
    this.routingService.openScheduleMeeting(this.learnerId);
  }

  onViewDataHistory() {
    this.routingService.dataHistory(this.learnerId);
  }

  onInviteLearnerToPortal() {
    this.notificationService.confirmation(
      'Are you sure you wish to allow ' + this.learnerService.learnerSummary?.firstName + ' access to the portal?',
      () => {
        const dialogRef = this.dialog.open(LearnerInviteModalComponent, {
          width: '950px',
          data: {
            learnerName: this.learnerService.learnerSummary?.firstName,
          },
        });
        dialogRef.afterClosed().subscribe((learnerInviteForm: FormGroup) => {
          if (learnerInviteForm && learnerInviteForm.valid) {
            this.learnerService.inviteToPortal(this.learnerId, learnerInviteForm.get('learnerEmail').value).subscribe(() => {
              this.notificationService.success('Successfully sent portal invite to ' + learnerInviteForm.get('learnerEmail').value);
            });
          }
        });
      }
    );
  }

  private loadData(learnerId): Promise<LearnerSummary> {
    return this.learnerService
      .getLearnerSummary(learnerId)
      .pipe(catchError((err) => (err.status === 404 ? of(null) : throwError(err))))
      .toPromise();
  }

  private getActivePartBCases() {
    return this.learnerService
      .getActiveCases(this.learnerId, this.intakeTypeEnum.PartB)
      .pipe(catchError((err) => (err.status === 404 ? of(null) : throwError(err))))
      .toPromise();
  }
}

// noinspection AngularMissingOrInvalidDeclarationInModule
@Component({
  selector: 'app-dialog-temporary-help',
  template: `<h3>Help: Learner Management</h3>
    <hr />
    <p>Help content is pending for this feature.</p>
    <p>Click anywhere outside of this box to close.</p>`,
})
export class DialogTemporaryHelpComponent {
  constructor(public dialogRef: MatDialogRef<DialogTemporaryHelpComponent>) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}
