<h3 class="mat-dialog-title" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>Invite {{ learnerName }} to Portal</h3>

<div class="mat-dialog-content py-2" [formGroup]="formGroup">
  <app-text-field label="{{ learnerName | firstNamePossessive }} Email" formControlName="learnerEmail"></app-text-field>
  <div style="overflow-y: scroll; height: 200px">
    <h3>Read and Accept Terms and Conditions</h3>
    <div>
      <p>
        The ACHIEVE Family Portal is an online tool developed for parents/legal guardians ("Guardians"), Learners who have reached the age
        of majority, and other Learners with permission from Guardians (collectively, "Learners") to access timely and secure information.
        <br /><br />
        Access to the ACHIEVE Family portal provides your Learner with many benefits, some of which include:
      </p>
      <ul>
        <li>
          Access to information and documentation in one centralized portal, including historical data such as assessment information,
          evaluations, and IFSP/IEP documents
        </li>
        <li>A calendar with information about scheduled meetings and deadlines related to the Learner</li>
        <li>Contact information for the Learner’s IEP Facilitator</li>
        <li>Access to a wide array of online help and resources</li>
      </ul>
      <p>
        All of these things make it easier for Learners to actively participate in the educational decision-making process and improve
        communication between team members. This access is a free and optional service available to Learners once they have been invited.
        Once Learners exit IDEA services, access to the ACHIEVE Family Portal will be deactivated after 90 days.
      </p>
      <p>
        By agreeing to activate this user account as the authorized IDEA Parent on behalf of your Learner, you agree to abide by and support
        the Terms of Condition and Use and user expectations set forth here:
      </p>

      <ul>
        <li>
          All Learners who use the ACHIEVE Family Portal to access information contained therein consent to electronic monitoring and
          understand that they are granted access to a private network used as an educational tool by the Iowa Department of Education
          employees. Account activity is electronically recorded.
        </li>
        <li>
          The Department reserves the right to discontinue the use of the ACHIEVE Family Portal at any time, with, or without, notice. In
          addition, the Department reserves the right to modify or terminate any user’s access to the ACHIEVE Family Portal temporarily or
          permanently at any time, at its sole discretion, with notice.
        </li>
        <li>
          The Department reserves the right to add, modify or delete functions viewed via the ACHIEVE Family Portal at any time without
          notice.
        </li>
        <li>
          The Department reserves the right, at its discretion, to modify, add, or delete provisions of the Terms and Conditions of Use. All
          Guardians on behalf of Learners who have not yet reached the age of majority are responsible for periodically reviewing the Terms
          and Conditions of Use for such changes. Continued Learner use of the ACHIEVE Family Portal after such changes have been made and
          posted on the website indicates Guardian’s acceptance of such changes.
        </li>
        <li>
          The Department, in its sole discretion, reserves the right to request that Learners bring photo identification, or other proof of
          identity, to the attending Area Education Agency or District in order to verify their identity should any question arise
          concerning the validity of the acceptance of this Agreement.
        </li>
        <li>
          All Learner users must act in a responsible, ethical, and legal manner. If a Learner is identified as a security risk, they will
          be denied access to the site.
        </li>

        <li>
          Learners must not:
          <ul>
            <li>Attempt to harm or destroy Area Education Agency, District, or network data.</li>
            <li>
              Attempt to access information concerning Learners other than their own or attempt to access an account assigned to another
              person.
            </li>
            <li>
              Use the ACHIEVE Family Portal or the State's networks for any illegal activity, including, but not limited to violation of
              Federal and State Data Privacy laws. Anyone found to be in violation of these laws will be subject to civil and/or criminal
              prosecution.
            </li>
            <li>Share your password or allow anyone other than yourself to use your ACHIEVE Family Portal account.</li>
            <li>Set any computer to automatically log in to the ACHIEVE Family Portal.</li>
            <li>Intentionally disrupt the use of the network or devices attached to the network.</li>
            <li>
              Use the system to develop malicious programs or computer viruses that harass other users, infiltrate a computer or computer
              system, damage the software components of a computer or computing system.
            </li>
            <li>Load, transmit, or intentionally receive hate mail, harassing content, and other antisocial content.</li>
            <li>
              Use the system to process or upload pornographic material, inappropriate text files, illegal software, or files dangerous to
              the integrity of the local area network or any attached device.
            </li>
            <li>Make unauthorized copies of software or media or other copyrighted content found on the ACHIEVE system.</li>
          </ul>
        </li>
        <li>
          Learners must:
          <ul>
            <li>Log out of your ACHIEVE Family Portal user account when you are not at your computer.</li>
            <li>Agree that hardware or software shall not be destroyed, modified, damaged or abused in any way.</li>
            <li>
              Notify your Area Education Agency or District immediately if you identify a security problem within the ACHIEVE Family Portal
              without demonstrating the problem to anyone else.
            </li>
          </ul>
        </li>
        <li>
          Security Features
          <ul>
            <li>
              Access is made available through a secure Internet site. Account holders are responsible for not sharing their passwords and
              to properly protect or destroy any printed or electronic documentation generated from or about the ACHIEVE Family Portal site.
            </li>
            <li>Users will be automatically logged off if they leave their web browser open and inactive for a period of 30 minutes.</li>
          </ul>
        </li>
        <li>
          Limitation of Iowa Department of Education Liability:
          <ul>
            <li>
              ACHIEVE will use all reasonable measures to protect Learner information from unauthorized viewing. THE DEPARTMENT WILL NOT BE
              LIABLE FOR UNAUTHORIZED USE OF THE ACHIEVE FAMILY PORTAL SYSTEM OR INTERNET.
            </li>
            <li>
              The Department does not promise any particular level or method of access to the ACHIEVE Family Portal for viewing Learner
              information.
            </li>
            <li>The Department will not be responsible for actions taken by Learners that compromise their information.</li>
          </ul>
        </li>
      </ul>
    </div>
  </div>

  <div>
    <p>Accept Terms & Conditions</p>
    <p>
      As a <a href="https://sites.ed.gov/idea/regs/b/a/300.30" target="_blank">Parent/Legal Guardian</a> of a Learner who has not yet
      reached the age of majority/date of rights transfer, you may invite your Learner to create an account in the ACHIEVE Family Portal.
    </p>
    <p>
      All ACHIEVE Family Portal account holders can complete family surveys and view, download, and print unsigned consent forms and
      IFSP/IEP records. This includes Meeting Notices, Prior Written Notices, progress monitoring data, and more. Access to the ACHIEVE
      Family Portal is restricted to view-only for learners who have not yet reached the age of majority/date of rights transfer. This means
      learners can view IEP records, but cannot sign consent forms electronically. Learners also will not receive emails when new consent
      forms are available to review.
    </p>
    <p>
      By inviting your learner to the ACHIEVE Family Portal, you acknowledge that your Learner will be able to view IEP documents and
      information, but will not receive email notifications when new documents become available, unless your Learner signs a Consent to
      Receive Electronic Communication upon reaching the age of majority. You further acknowledge that your learner will be able to view and
      print physical copies of consent forms, but will not be eligible to provide electronic signatures on consent forms at this time.
    </p>
    <p>
      After account registration is complete, your learner may change access preferences in the ACHIEVE Family Portal account Settings upon
      reaching the age of majority/date of rights transfer.
    </p>

    <mat-checkbox formControlName="termsAndConditions" aria-labelledby="Please accept the Terms & Conditions">
      <mat-label
        [ngClass]="{
          asterisk_input: formGroup.hasError('delayValidator')
        }"
        >Accept Terms & Conditions</mat-label
      >
    </mat-checkbox>
  </div>
</div>

<div class="mat-dialog-actions justify-content-between align-items-center">
  <div>
    <button mat-raised-button aria-label="Cancel Button" (click)="onCancel()" cdkFocusInitial tabindex="0">Cancel</button>
    <button
      mat-raised-button
      aria-label="Submit Button"
      color="primary"
      (click)="onSubmit()"
      cdkFocusInitial
      tabindex="0"
      [disabled]="formGroup.invalid"
    >
      Submit
    </button>
  </div>
</div>
