import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppMatModule } from '../shared/app-mat.module';
import { SharedModule } from '../shared/shared.module';
import { AuthRoutingModule } from './auth-routing.module';
import { FamilyMemberRegistrationFormComponent } from './family-member-registration/family-member-registration-form/family-member-registration-form.component';
import { FamilyMemberRegistrationComponent } from './family-member-registration/family-member-registration.component';
import { LoginFormComponent } from './login/login-form/login-form.component';
import { LoginComponent } from './login/login.component';
import { RegisterFormComponent } from './register/register-form/register-form.component';
import { RegisterComponent } from './register/register.component';
import { FamilyMemberPortalRegistrationComponent } from './family-member-registration/family-member-portal-registration/family-member-portal-registration.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ForgotPasswordFormComponent } from './forgot-password/forgot-password-form/forgot-password-form.component';
import { ResetPasswordComponent } from './forgot-password/reset-password/reset-password.component';
import { LearnerRegistrationComponent } from './learner-registration/learner-registration.component';
import { LearnerPortalRegistrationComponent } from './learner-registration/learner-portal-registration/learner-portal-registration.component';
import { LearnerPortalRegistrationFormComponent } from './learner-registration/learner-portal-registration-form/learner-portal-registration-form.component';
import { IvrsLoginComponent } from './ivrs/ivrs-login/ivrs-login.component';
import { IvrsForgotPasswordComponent } from './ivrs/ivrs-forgot-password/ivrs-forgot-password.component';
import { IvrsMemberRegistrationComponent } from './ivrs/ivrs-member-registration/ivrs-member-registration.component';
import { IvrsResetPasswordComponent } from './ivrs/ivrs-reset-password/ivrs-reset-password.component';
import { InteractiveAccessLoginComponent } from './interactive-access/interactive-access-login/interactive-access-login.component';
import { InteractiveAccessResetPasswordComponent } from './interactive-access/interactive-access-reset-password/interactive-access-reset-password.component';
import { InteractiveAccessForgotPasswordComponent } from './interactive-access/interactive-access-forgot-password/interactive-access-forgot-password.component';
import { FamilyLoginComponent } from './family/family-login/family-login.component';
import { FamilyLoginFormComponent } from './family/family-login/login-form/family-login-form.component';
import { FamilyResetPasswordComponent } from './family/family-reset-password/family-reset-password.component';
import { FamilyLandingComponent } from './family/family-landing/family-landing.component';
import { FamilyForgotPasswordComponent } from './family/family-forgot-password/family-forgot-password.component';
import { LoginMfaComponent } from './login/mfa/login-mfa.component';
import { FamilyMemberAccessLockedComponent } from './family-member-registration/family-member-access-locked/family-member-access-locked.component';

@NgModule({
  declarations: [
    LoginFormComponent,
    LoginMfaComponent,
    LoginComponent,
    RegisterComponent,
    RegisterFormComponent,
    FamilyMemberRegistrationComponent,
    FamilyMemberRegistrationFormComponent,
    FamilyMemberPortalRegistrationComponent,
    FamilyLandingComponent,
    FamilyLoginComponent,
    FamilyLoginFormComponent,
    FamilyResetPasswordComponent,
    FamilyForgotPasswordComponent,
    ForgotPasswordComponent,
    ForgotPasswordFormComponent,
    ResetPasswordComponent,
    LearnerRegistrationComponent,
    LearnerPortalRegistrationComponent,
    LearnerPortalRegistrationFormComponent,
    IvrsLoginComponent,
    IvrsForgotPasswordComponent,
    IvrsMemberRegistrationComponent,
    IvrsResetPasswordComponent,
    InteractiveAccessLoginComponent,
    InteractiveAccessResetPasswordComponent,
    InteractiveAccessForgotPasswordComponent,
    FamilyMemberAccessLockedComponent,
  ],
  imports: [AppMatModule, AuthRoutingModule, CommonModule, HttpClientModule, ReactiveFormsModule, FormsModule, SharedModule],
  exports: [LoginComponent, LoginFormComponent],
})
export class AuthModule {}
